exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-announcements-index-tsx": () => import("./../../../src/pages/announcements/index.tsx" /* webpackChunkName: "component---src-pages-announcements-index-tsx" */),
  "component---src-pages-announcements-new-tsx": () => import("./../../../src/pages/announcements/new.tsx" /* webpackChunkName: "component---src-pages-announcements-new-tsx" */),
  "component---src-pages-auth-sign-in-tsx": () => import("./../../../src/pages/auth/sign-in.tsx" /* webpackChunkName: "component---src-pages-auth-sign-in-tsx" */),
  "component---src-pages-auth-sign-out-tsx": () => import("./../../../src/pages/auth/sign-out.tsx" /* webpackChunkName: "component---src-pages-auth-sign-out-tsx" */),
  "component---src-pages-auth-sign-up-tsx": () => import("./../../../src/pages/auth/sign-up.tsx" /* webpackChunkName: "component---src-pages-auth-sign-up-tsx" */),
  "component---src-pages-contacts-[id]-tsx": () => import("./../../../src/pages/contacts/[id].tsx" /* webpackChunkName: "component---src-pages-contacts-[id]-tsx" */),
  "component---src-pages-contacts-all-tsx": () => import("./../../../src/pages/contacts/all.tsx" /* webpackChunkName: "component---src-pages-contacts-all-tsx" */),
  "component---src-pages-contacts-customers-[id]-tsx": () => import("./../../../src/pages/contacts/customers/[id].tsx" /* webpackChunkName: "component---src-pages-contacts-customers-[id]-tsx" */),
  "component---src-pages-contacts-customers-index-tsx": () => import("./../../../src/pages/contacts/customers/index.tsx" /* webpackChunkName: "component---src-pages-contacts-customers-index-tsx" */),
  "component---src-pages-contacts-customers-new-tsx": () => import("./../../../src/pages/contacts/customers/new.tsx" /* webpackChunkName: "component---src-pages-contacts-customers-new-tsx" */),
  "component---src-pages-contacts-suppliers-[id]-tsx": () => import("./../../../src/pages/contacts/suppliers/[id].tsx" /* webpackChunkName: "component---src-pages-contacts-suppliers-[id]-tsx" */),
  "component---src-pages-contacts-suppliers-index-tsx": () => import("./../../../src/pages/contacts/suppliers/index.tsx" /* webpackChunkName: "component---src-pages-contacts-suppliers-index-tsx" */),
  "component---src-pages-contacts-suppliers-new-tsx": () => import("./../../../src/pages/contacts/suppliers/new.tsx" /* webpackChunkName: "component---src-pages-contacts-suppliers-new-tsx" */),
  "component---src-pages-employees-[id]-tsx": () => import("./../../../src/pages/employees/[id].tsx" /* webpackChunkName: "component---src-pages-employees-[id]-tsx" */),
  "component---src-pages-employees-index-tsx": () => import("./../../../src/pages/employees/index.tsx" /* webpackChunkName: "component---src-pages-employees-index-tsx" */),
  "component---src-pages-employees-new-tsx": () => import("./../../../src/pages/employees/new.tsx" /* webpackChunkName: "component---src-pages-employees-new-tsx" */),
  "component---src-pages-expenses-index-tsx": () => import("./../../../src/pages/expenses/index.tsx" /* webpackChunkName: "component---src-pages-expenses-index-tsx" */),
  "component---src-pages-expenses-new-tsx": () => import("./../../../src/pages/expenses/new.tsx" /* webpackChunkName: "component---src-pages-expenses-new-tsx" */),
  "component---src-pages-income-index-tsx": () => import("./../../../src/pages/income/index.tsx" /* webpackChunkName: "component---src-pages-income-index-tsx" */),
  "component---src-pages-income-new-tsx": () => import("./../../../src/pages/income/new.tsx" /* webpackChunkName: "component---src-pages-income-new-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oportunities-[id]-tsx": () => import("./../../../src/pages/oportunities/[id].tsx" /* webpackChunkName: "component---src-pages-oportunities-[id]-tsx" */),
  "component---src-pages-oportunities-index-tsx": () => import("./../../../src/pages/oportunities/index.tsx" /* webpackChunkName: "component---src-pages-oportunities-index-tsx" */),
  "component---src-pages-oportunities-new-tsx": () => import("./../../../src/pages/oportunities/new.tsx" /* webpackChunkName: "component---src-pages-oportunities-new-tsx" */),
  "component---src-pages-projects-[id]-attachments-tsx": () => import("./../../../src/pages/projects/[id]/attachments.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-attachments-tsx" */),
  "component---src-pages-projects-[id]-custom-fields-tsx": () => import("./../../../src/pages/projects/[id]/custom-fields.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-custom-fields-tsx" */),
  "component---src-pages-projects-[id]-index-tsx": () => import("./../../../src/pages/projects/[id]/index.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-index-tsx" */),
  "component---src-pages-projects-[id]-payments-tsx": () => import("./../../../src/pages/projects/[id]/payments.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-payments-tsx" */),
  "component---src-pages-projects-[id]-settings-tsx": () => import("./../../../src/pages/projects/[id]/settings.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-settings-tsx" */),
  "component---src-pages-projects-[id]-tasks-tsx": () => import("./../../../src/pages/projects/[id]/tasks.tsx" /* webpackChunkName: "component---src-pages-projects-[id]-tasks-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-new-tsx": () => import("./../../../src/pages/projects/new.tsx" /* webpackChunkName: "component---src-pages-projects-new-tsx" */),
  "component---src-pages-settings-catalog-[id]-tsx": () => import("./../../../src/pages/settings/catalog/[id].tsx" /* webpackChunkName: "component---src-pages-settings-catalog-[id]-tsx" */),
  "component---src-pages-settings-catalog-index-tsx": () => import("./../../../src/pages/settings/catalog/index.tsx" /* webpackChunkName: "component---src-pages-settings-catalog-index-tsx" */),
  "component---src-pages-settings-custom-fields-[id]-tsx": () => import("./../../../src/pages/settings/custom-fields/[id].tsx" /* webpackChunkName: "component---src-pages-settings-custom-fields-[id]-tsx" */),
  "component---src-pages-settings-custom-fields-index-tsx": () => import("./../../../src/pages/settings/custom-fields/index.tsx" /* webpackChunkName: "component---src-pages-settings-custom-fields-index-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */)
}

